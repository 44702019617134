import { Box, TextareaAutosize, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { useMutation } from "react-query";
import useAxios from "../hooks/useAxios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

const Textarea = styled(TextareaAutosize)(
  () => `
    width: 100%;
    border-radius: 0.5rem ;
    padding: 1rem ;
    font-size: 1rem;
  `
);

const SubmitButton = styled(LoadingButton)(
  () => `
  &:hover {
    background-color: #49EB64;
  }
  `
);

const SurveyForm = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const encodedLocation = searchParams.get("bG9jYXRpb24");

  const decodedLocation = atob(encodedLocation);

  // console.log(decodedLocation);

  // const word = "office";
  // const encode = btoa(word);

  // console.log("Original Word: ", word);
  // console.log("encoded Word: ", encode);

  const api = useAxios();
  const [data, setData] = useState({
    message: "",
    location: "",
  });

  const MySwal = withReactContent(Swal);

  const handleOnChange = (e) => {
    setData({
      ...data,
      [e.target?.name]: e.target.value,
      location: decodedLocation,
    });
  };

  const { mutate, isLoading } = useMutation(
    () => {
      return api.post("/api/customer", data, {
        validateStatus: function (status) {
          return status <= 500;
        },
      });
    },
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          MySwal.fire({
            title: <strong>ကျေးဇူးတင်ပါတယ်</strong>,
            icon: "success",
          });
        } else {
          MySwal.fire({
            title: <strong>သင်၏ဖောင် တင်သွင်းမှု မအောင်မြင်ပါ။</strong>,
            icon: "error",
          });
        }

        setData({
          message: "",
        });
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate();
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        position: "relative",
        minHeight: "100vh",
        color: "#fff",
        pt: "10rem",
        pb: "5rem",
      }}
    >
      {/* <CardMedia
        component="img"
        src="/images/background.png"
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          zIndex: -10,
        }}
        alt="background"
      /> */}
      <Box
        sx={{
          // bgcolor: "primary.main",
          p: { md: "3rem", xs: "2rem" },
          borderRadius: "15px",
          fontWeight: "fontWeightBold",
          mx: "auto",
          width: { xs: "90%", md: "50%" },
        }}
      >
        <Box
          component="img"
          src="/images/logo.png"
          sx={{
            display: "block",
            width: "300px",
            mx: "auto",
            mt: "-10rem",
          }}
        />
        <Box sx={{ mt: { md: "2rem", xs: "1rem" } }}>
          <Typography
            sx={{ color: "primary.light", fontWeight: "bold", mb: "0.5rem" }}
          >
            အကြံပြုချက်
            <span> *</span>
          </Typography>
        </Box>
        <Textarea
          required
          aria-label="about textarea"
          placeholder="‌အကြောင်းအရာ"
          minRows={10}
          name="message"
          value={data.message}
          onChange={handleOnChange}
        />
        <SubmitButton
          loading={isLoading}
          type="submit"
          className="submit-btn"
          fullWidth
          sx={{
            bgcolor: "primary.light",
            mt: "2rem",
            mx: "auto",
            height: "55px",
            display: "block",
            color: "#000",
            fontWeight: "bold",
            borderRadius: "10px"
          }}
        >
          ပို့မည်
        </SubmitButton>
      </Box>
    </Box>
  );
};

export default SurveyForm;
